<template>

  <div class="table-row">
    <div class="icons">
      <img :src="userContract.iconUrl" />
      <span>{{ userContract.name }}</span>
    </div>
    <div class="font-mono">
      <Tooltip maxWidth="25rem">
        <template #trigger>
          <span class="inline 2xl:hidden">
            {{ truncateAddress(userContract.address) }}
          </span>
        </template>
        <span>{{ userContract.address }}</span>
      </Tooltip>
      <span class="hidden 2xl:inline">
        {{ userContract.address }}
      </span>
    </div>
    <div class="icons">
      <img :src="networkOption.iconUrl" />
      <span>{{ networkOption.apiRecord.name }}</span>
    </div>
    <div>
      <span class="badge gray subtle font-normal">
        {{ userContract.type === 'other' ? titleCase(userContract.type) : userContract.type.toUpperCase() }}
      </span>
    </div>
    <div>
      {{ formatNumber(userContract.actionCount) }}
    </div>
    <div>
      {{ formatNumber(userContract.patchCount) }}
    </div>
    <div class="timestamp">
      {{ formatTimestamp(userContract.insertedAt) }}
    </div>
    <div class="small">
      <OverflowMenu :forceAlignRight="true" @click.stop>
        <template #trigger>
          <button class="small ghost"><EllipsisIcon /></button>
        </template>
        <!-- <button class="link"><InfoIcon /> Details</button> -->
        <button :disabled="isAPIReadOnly" class="link" @click="createPatch"><AddIcon /> Create Patch</button>
        <button :disabled="isAPIReadOnly" class="link" @click="openRenameUserContractModal"><RenameIcon /> Rename</button>
        <button :disabled="isAPIReadOnly" class="link delete-button" @click="openDeleteUserContractModal"><TrashIcon /> Delete</button>
      </OverflowMenu>
    </div>
  </div>

</template>

<script>

  import { mapState, mapGetters } from 'vuex'

  import useFilters from '@/composables/useFilters'

  import AddIcon from '@/assets/icons/add.svg'
  // import InfoIcon from '@/assets/icons/info.svg'
  import TrashIcon from '@/assets/icons/trash.svg'
  import RenameIcon from '@/assets/icons/rename.svg'
  import EllipsisIcon from '@/assets/icons/ellipsis.svg'

  import Tooltip from '@/components/utils/Tooltip.vue'
  import OverflowMenu from '@/components/utils/OverflowMenu.vue'

  export default {
    inject: ['$mixpanel'],
    components: {
      AddIcon,
      Tooltip,
      // InfoIcon,
      TrashIcon,
      RenameIcon,
      EllipsisIcon,
      OverflowMenu,
    },
    props: {
      userContract: {
        type: Object,
        required: true,
      },
    },
    setup() {

      const { titleCase, formatNumber, formatTimestamp, truncateAddress } = useFilters()

      return {
        titleCase,
        formatNumber,
        formatTimestamp,
        truncateAddress,
      }

    },
    computed: {
      ...mapState('app', ['isAPIReadOnly']),
      ...mapGetters('app', ['getActionSettingBySlug', 'getTriggerSettingBySlug']),
      networkOption() {
        return this.$store.state.app.networkOptionsIdMap[this.userContract.networkId]
      },
    },
    data() {
      return {
        isLoading: false,
      }
    },
    methods: {
      openDeleteUserContractModal() {

        this.$mixpanel.onReady((mixpanel) => {
          mixpanel.track('Contract Overview "Delete Contract" Button Clicked', {
            userContract: this.userContract,
          })
        })

        this.$store.dispatch('modals/OPEN_MODAL', {
          name: 'DeleteUserContractModal',
          props: {
            userContract: this.userContract,
            onSuccess: () => {
              // @NOTE: even though the user contract was deleted, this
              //  component still has a reference to it in memory so this object
              //  is not empty
              this.$mixpanel.onReady((mixpanel) => {
                mixpanel.track('User Contract Deleted', {
                  userContract: this.userContract,
                })
              })
            },
          },
        })

      },
      openRenameUserContractModal() {

        const oldName = this.userContract.name

        this.$mixpanel.onReady((mixpanel) => {
          mixpanel.track('User Contract Overview "Rename User Contract" Button Clicked', {
            userContract: this.userContract,
          })
        })

        this.$store.dispatch('modals/OPEN_MODAL', {
          name: 'RenameUserContractModal',
          props: {
            userContract: this.userContract,
            onSuccess: () => {

              this.$mixpanel.onReady((mixpanel) => {
                mixpanel.track('User Contract Renamed', {
                  newName: this.userContract.name,
                  userContract: this.userContract,
                  oldName,
                })
              })

              this.$store.dispatch('toast/CREATE_TOAST', {
                text: 'Your contract has been renamed!',
                type: 'success',
              })

            },
          },
        })

      },
      createPatch() {

        const newTemplate = {}

        const triggerOption = this.$store.state.app.triggerOptionsSlugMap['smart-contract-activity']

        newTemplate.triggerId = triggerOption.apiRecord.id
        newTemplate.networkId = this.userContract.networkId
        newTemplate.userContractId = this.userContract.id

        this.$store.commit('forms/SET_FORM_TEMPLATE', {
          formName: 'createPatchForm',
          newTemplate,
        })

        this.$mixpanel.onReady((mixpanel) => {
          mixpanel.track('Contract Overview "Make a Copy" Button Clicked', {
            userContract: this.userContract,
            newTemplate,
          })
        })

        this.$router.push({
          name: 'PatchCreate',
          params: {
            referrerLink: 'contract-overview-create-patch',
          },
        })

      },
    },
  }

</script>

<style lang="stylus" scoped>

  .delete-button
    @apply text-primary-500

    &:hover:not([disabled])
      @apply text-primary-500

</style>
